<template>
  <div
    class="modal fade"
    id="dialogLogout"
    tabindex="-1"
    aria-labelledby="dialogLogoutLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="dialogLogoutLabel">ออกจากระบบ</h5>
          <button
            id="button-close-dialog-logout"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>กรุณาเลือกการดำเนินการ:</p>
          <div class="d-grid gap-2">
            <button class="btn btn-danger" type="button" @click="logout">
              ออกจากเครื่อง
            </button>
            <button class="btn btn-warning" type="button" @click="closeShift">
              ปิดยอด
            </button>
            <button class="btn btn-info" type="button" @click="summaryShift">
              สรุปยอดปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DialogLogout",
  props: {
    isCloseDialogLogout: Boolean,
  },
  watch: {
    isCloseDialogLogout(val) {
      if (val) {
        document.getElementById("button-close-dialog-logout").click();
      }
    },
  },
  emits: ["logout", "closeShift", "summaryShift"],
  methods: {
    logout() {
      // Implement logout logic here
      this.$emit("logout");
    },
    closeShift() {
      // Implement close shift logic here
      this.$emit("closeShift");
    },
    summaryShift() {
      // Implement summary shift logic here
      this.$emit("summaryShift");
    },
  },
};
</script>

<style scoped>
.modal-content {
  border-radius: 0.5rem;
}
</style>
