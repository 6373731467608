<template>
  <div
    v-if="visible"
    class="modal fade show"
    style="display: block"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Line QR Code</h5>
          <button
            type="button"
            class="btn btn-sm"
            @click="$emit('close')"
            aria-label="Close"
          >
            <i
              class="bi bi-x m-0 p-0"
              id="close-btn"
              style="font-size: 25px"
            ></i>
          </button>
        </div>
        <div class="modal-body d-flex justify-content-center">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
